<template>
  <!-- NavBa r-->
  <nav
    id="navbar"
    class="navbar navbar-expand-lg navbar-light"
    style="background-color: #e3f2fd"
  >
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img
          class="img-fluid"
          src="https://sevist.ru/upload/resize_cache/webp/include/logotype.webp"
          alt=""
          width="100"
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li>
            <router-link class="dropdown-item" to="/">Заказы</router-link>
          </li>
          <li>
            <router-link class="dropdown-item" to="/routeSheets">Маршрутные листы</router-link>
          </li>
          <li>
            <router-link class="dropdown-item" to="/users"
              >Пользователи</router-link
            >
          </li>
          <li>
            <router-link class="dropdown-item" to="/kkmtasklist"
              >Чеки</router-link
            >
          </li>
          <li>
            <router-link class="dropdown-item" to="/settings"
              >Настройки</router-link
            >
          </li>
          <li v-if="authenticated">
            <a class="dropdown-item" href="https://confluence.cs-develop.ru/view/7ffa2575-38f0-4b91-aa49-133fb717f0c7/" 
            target="_blank">База знаний </a>
          </li>
          <li>
            <div
              class="justify-content-center form-group"
              v-if="!authenticated"
            >
              <input
                v-model="username"
                type="text"
                class="form-control"
                placeholder="Имя пользователя"
              />
              <input
                v-model="password"
                type="password"
                class="form-control"
                placeholder="Пароль"
              />
            </div>
            <div class="d-flex justify-content-center btn-group">
              <button id="loginButton" class="btn btn-primary" @click="login">
                {{ loginLabel }}
              </button>
              <button id="logOutButton" class="btn btn-danger" @click="logout">
                Выйти
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
 
</template>

<script>
import { ref } from "vue";
import { onMounted } from "vue";

export default {
  setup() {
    const authenticated = ref(false);
    const loginLabel = ref("Войти");
    const username = ref("");
    const password = ref("");

    const login = async () => {
      localStorage.setItem("username", username.value);
      localStorage.setItem("password", password.value);
      checkAuth();
    };

    const checkAuth = async () => {
      console.log("/login");
      let res = await fetch(process.env.VUE_APP_API_URL + "/login", {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa(
              localStorage.getItem("username") +
                ":" +
                localStorage.getItem("password")
            ),
        },
      });
      loginLabel.value = "Войти";
      authenticated.value = false;
      console.log(res.status);
      if (res.status == 200) {
        loginLabel.value = await res.text();
        authenticated.value = true;
      }
    };

    const logout = async () => {
      console.log("logout");
      localStorage.removeItem("username");
      localStorage.removeItem("password");
      checkAuth();
    };

    const userAdmin = () => {
      return localStorage.getItem("admin") == "admin";
    };

    onMounted(async () => {
      await checkAuth();
    });

    return {
      authenticated,
      username,
      password,
      login,
      logout,
      loginLabel,
      userAdmin,
    };
  },
};
</script>
