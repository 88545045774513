<template>
  <div class="container">
    <h1>Маршрутные листы</h1>

    <div class="d-flex justify-content-center m-3 btn-group">
      <input
        v-model="deliverDate"
        type="date"
        class="form-control"
        placeholder="Дата доставки"
        @input="getDocs"
      />
    </div>

    <!-- search -->
    <div id="searchGroup" class="d-flex justify-content-center m-3 btn-group">
      <input
        v-model="searchText"
        type="text"
        class="form-control"
        placeholder="Поиск"
      />
    </div>

    <div
      v-for="doc in docsFiltered"
      :key="doc.guid"
      class="accordion mb-2"
      :id="`routeSheet-${doc.guid}`"
    >
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#collapse-routeSheet-${doc.guid}`"
            aria-expanded="true"
            :aria-controls="`collapse-routeSheet-${doc.guid}`"
          >
            <div class="row w-100">
              <div class="col">
                <p class="mb-0">
                  {{ doc.docNumber }} от {{ dateFormat(doc.date) }}
                </p>
                <p class="mb-0">{{ doc.courier }}</p>
              </div>
            </div>
          </button>
        </h2>
        <div
          :id="`collapse-routeSheet-${doc.guid}`"
          class="accordion-collapse collapse"
          :data-bs-parent="`#routeSheet-${doc.guid}`"
        >
          <div class="accordion-body">
            <li>Автомобиль: {{ doc.carName }}</li>

            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr
                    v-for="(product, productIndex) in doc.goods"
                    :key="product.guidProduct"
                    :class="{
                      currentProduct: isCurrentProduct(doc._id, productIndex),
                    }"
                  >
                    <td>{{ product.name }}</td>
                    <td>
                      <span v-if="product.guidProduct=='isReturned'">Кол-во: {{ marksByProduct(doc, productIndex).length }}</span>
                      <span v-if="product.guidProduct!='isReturned'">Кол-во: {{ product.qty }}</span>

                      <button
                        class="btn btn-warning"
                        data-order="${doc._id}"
                        @click="startScan(doc, productIndex)"
                      >
                        M{{ marksByProduct(doc, productIndex).length }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              v-if="
                currentOrder &&
                  currentOrder._id == doc._id &&
                  currentProductIndex >= 0
              "
            >
              <div class="row mb-1 fw-bold">
                <div class="col-6">Марка</div>
                <div class="col-2">Подобрана</div>
                <div class="col-1"></div>
              </div>

              <div
                v-for="(mark, index) in marksByProduct(
                  currentOrder,
                  currentProductIndex
                )"
                :key="index"
                class="row mb-1"
              >
                <div class="col-6" style="word-break: break-all;">
                  <small>{{ mark.cis }}</small>
                </div>
                <div class="col-2">
                  <span v-if="isMarksCollected(mark)">+</span>
                </div>
                <div class="col-1">
                  <button
                    class="btn btn-sm btn-danger"
                    @click="removeMark(doc, mark._id)"
                  >
                    X
                  </button>
                </div>
              </div>
            </div>

            <div :id="`scanner-${doc._id}`" class="scanner"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import useCommonMixin from "@/components/commonMixin.js";

export default {
  setup() {
    const docs = ref([]);
    const deliverDate = ref([]);
    const searchText = ref("");

    const {
      getRouteSheet,
      dateFormat,
      settings,
      getSettings,
      currentOrder,
      scan,
      scanner,
      clearScanner,
      deleteMark,
      addMarkToRouteSheet,
      currentProductIndex,
    } = useCommonMixin();

    const getDocs = async () => {
      docs.value = await getRouteSheet(deliverDate.value);
    };

    const docsFiltered = computed(() => {
      let result = docs.value;
      if (searchText.value.length >= 3) {
        result = result.filter((item) => {
          let itemString = JSON.stringify(item).toLowerCase();
          let indexSearchText = itemString.indexOf(
            searchText.value.toLowerCase()
          );
          return indexSearchText >= 0;
        });
      }
      return result;
    });

    const isMarksCollected = computed(() => {
      return (mark) => {
        let result = false;
        if ("guidOrder" in mark && mark.guidOrder) result = true;
        if ("guidInvoice" in mark && mark.guidInvoice) result = true;
        return result;
      };
    });

    const removeMark = async (doc, markId) => {
      let confirm = window.confirm(`Удалить марку?`);
      if (!confirm) return;

      let data = { _id: markId, guidRouteSheet: doc.guid };
      let resp = await deleteMark(data);
      if (!resp.status) {
        let errorMsg = window.confirm(`${resp.errorMsg}`);
        console.log(errorMsg);
      }

      if (resp.status) doc.marks = resp.data;
    };

    const isCurrentProduct = computed(() => {
      return (orderId, productIndex) => {
        let result = false;
        if (
          currentOrder.value &&
          currentOrder.value._id == orderId &&
          currentProductIndex.value >= 0 &&
          currentProductIndex.value == productIndex
        ) {
          result = true;
        }

        return result;
      };
    });

    const marksByProduct = computed(() => {
      return (doc, productIndex) => {
        let result = [];
        if (doc) {
          let guidProduct = doc.goods[productIndex].guidProduct;
          result = doc.marks.filter((item) => item.guidProduct == guidProduct);
        }
        return result;
      };
    });

    let Html5QrcodeObj = undefined;

    const startScan = async (order, productIndex) => {
      order.isRouteSheet = true;
      if (scannerMode.value == "camera") {
        clearScanner.value =
          currentOrder.value && order._id == currentOrder.value._id
            ? false
            : true;
        currentOrder.value = order;
        currentProductIndex.value = productIndex;
        if (clearScanner.value) {
          scan();
        }
      }

      if (scannerMode.value == "tsd") {
        currentOrder.value = order;
        currentProductIndex.value = productIndex;
      }
    };

    const handleClipboard = async (decodedText) => {
      console.log("TSD decodedText", decodedText);
      if (decodedText.length < 33) {
        // let confi = window.confirm(`Некорретная марка - ${decodedText}`);
        // console.log(confi);
        return;
      }
      if (currentOrder.value) {
        console.log("handleClipboard", currentOrder.value);
        let markData = {
          cis: decodedText,
          guidRouteSheet: currentOrder.value.guid,
          guidProduct:
            currentOrder.value.goods[currentProductIndex.value]["guidProduct"],
          isReturned:
            currentOrder.value.goods[currentProductIndex.value][
              "guidProduct"
            ] == "isReturned",
        };

        let resp = await addMarkToRouteSheet(markData);
        if (!resp.status) {
          let errorMsg = window.confirm(`${resp.errorMsg}`);
          console.log(errorMsg);
        }
        console.log(resp);
        if (resp.status) currentOrder.value.marks = resp.data;
      }
    };

    const scannerMode = computed(() => {
      let result = "camera";
      if (
        "scannerMode" in settings.value &&
        settings.value.scannerMode == "tsd"
      )
        result = "tsd";

      return result;
    });

    onMounted(async () => {
      await getSettings();
      console.log(Html5QrcodeObj);
      if (scannerMode.value == "tsd") {
        document.addEventListener("paste", (event) => {
          let paste = (event.clipboardData || window.clipboardData).getData(
            "text"
          );
          handleClipboard(paste);
          event.preventDefault();
        });
      }

      let today = new Date().toISOString().slice(0, 10);
      deliverDate.value = today;
      getDocs();
    });

    return {
      settings,
      docs,
      getDocs,
      deliverDate,
      searchText,
      docsFiltered,
      dateFormat,
      scanner,
      startScan,
      isMarksCollected,
      removeMark,
      currentProductIndex,
      isCurrentProduct,
      currentOrder,
      marksByProduct,
    };
  },
};
</script>
