import { createRouter, createWebHistory } from 'vue-router'
import OrderList from '../components/OrderList.vue'
import KkmTaskList from '../components/KkmTaskList.vue'
import UserList from '../components/UserList.vue'
import Cheque from '../components/Cheque.vue'
import Settings from '../components/Settings.vue'
import RouteSheetList from '../components/RouteSheetList.vue'

const routes = [
  {
    path: '/',
    component: OrderList,
  },
  {
    path: '/kkmtasklist',
    component: KkmTaskList
  },
  {
    path: '/users',
    component: UserList
  },
  {
    path: '/cheque/:chequeId',
    name: 'cheque',
    component: Cheque
  },
  {
    path: '/settings',
    component: Settings
  },
  {
    path: '/routeSheets',
    component: RouteSheetList
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
