import { ref, nextTick } from "vue";
import { /*Html5Qrcode,*/ Html5QrcodeScanner } from "html5-qrcode";

export default () => {
  const settings = ref({
    camera: '',
    scannerMode: '',
  })
  let Html5QrcodeObj = undefined
  const scanner = ref(false);
  const currentOrder = ref(false);
  const currentProductIndex = ref('')
  const clearScanner = ref(true)

  const getSettings = async () => {
    const settingsFromLocalstotrage = JSON.parse(
      localStorage.getItem("settings")
    );
    if (settingsFromLocalstotrage == null) {
      return;
    }
    settings.value = settingsFromLocalstotrage;
  };

  const saveSettings = () => {
    localStorage.setItem("settings", JSON.stringify(settings.value));
  };

  const dateFormat = (date) => {
    let dateFormated = new Date(date);
    return dateFormated.toLocaleString();
  };


  const loadCameraScanner = async () => {
    let scannerId = currentOrder.value ? `scanner-${currentOrder.value._id}` : `scanner`
    console.log('scanner', scanner.value);
    if (scanner.value) {

      if (Html5QrcodeObj && clearScanner.value) {
        Html5QrcodeObj.clear()
      }

      const config = {
        fps: 10,
        qrbox: { width: 250, height: 250 },
        showZoomSliderIfSupported: true,
        defaultZoomValueIfSupported: 2,
        showTorchButtonIfSupported: true,
      };

      Html5QrcodeObj = new Html5QrcodeScanner(
        scannerId,
        config,
      )

      Html5QrcodeObj.render(scannerSuccessCallback, onScanFailure);
    } else {
      document.getElementById(scannerId).innerHTML = "";
    }
  };

  // function timeout(ms) {
  //   return new Promise(resolve => setTimeout(resolve, ms));
// }

  const scannerSuccessCallback = async (decodedText, decodedResult) => {
    // await timeout(3000);

    console.log('decodedText, decodedResult', decodedText, decodedResult);
    console.log('currentOrder', currentOrder.value,)
    if (decodedText.length < 33) {
      let confi = window.confirm(`Некорретная марка - ${decodedText}`);
      console.log(confi);
      return
    }

    if (currentOrder.value) {
      if ('isRouteSheet' in currentOrder.value && currentOrder.value["isRouteSheet"]) {
        console.log("scannerSuccessCallback", "isRouteSheet");
        let markData = {
          cis: decodedText,
          guidRouteSheet: currentOrder.value.guid,
          guidProduct: currentOrder.value.goods[currentProductIndex.value]["guidProduct"],
          isReturned: currentOrder.value.goods[currentProductIndex.value]["guidProduct"] == 'isReturned'
        }

        let resp = await addMarkToRouteSheet(markData)
        if (!resp.status) {
          let errorMsg = window.confirm(`${resp.errorMsg}`);
          console.log(errorMsg);
        }
        console.log(resp);
        if(resp.status) currentOrder.value.marks = resp.data

        return
      }

      if (!('marks' in currentOrder.value.goods[currentProductIndex.value])) currentOrder.value.goods[currentProductIndex.value].marks = []
      let markIndex = -1
      for (const productItem of currentOrder.value.goods) {
        let markIndexCurrentProduct = productItem.marks.findIndex(item => item == decodedText)
        if (markIndexCurrentProduct >= 0) {
          markIndex = markIndexCurrentProduct
          break
        }
      }
      if (markIndex < 0) {

        // Проверка марки
        let data = {
          cis: decodedText,
          guidOrder: currentOrder.value.guid,
          guidRouteSheet: currentOrder.value.routeSheetGuid,
        };

        let resp = await checkMark(data);
        if(!resp.status) {
          let errorMsg = window.confirm(`${resp.errorMsg}`);
          console.log(errorMsg);
          return
        }

        currentOrder.value.goods[currentProductIndex.value].marks.push(decodedText)
        currentOrder.value.goods[currentProductIndex.value].marks = [...new Set(currentOrder.value.goods[currentProductIndex.value].marks)];

        await saveOrder(currentOrder.value)
      }
    }

  };

  const onScanFailure = () => {
    console.log('onScanFailure');
  }

  const scan = () => {
    scanner.value = true;
    let scannerElements = document.getElementsByClassName('scanner')
    scannerElements.forEach(item => {
      item.innerHTML = ""
    })

    loadCameraScanner();
  };

  const saveOrder = async (order) => {
    console.log("/saveOrder");
    let res = await fetch(process.env.VUE_APP_API_URL + "/saveOrder", {
      method: "POST",
      headers: {
        Authorization:
          "Basic " +
          btoa(
            localStorage.getItem("username") +
            ":" +
            localStorage.getItem("password")
          ),
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(order),
    });
    console.log(res.status);
    let resJson = undefined;
    if (res.status == 200) {
      resJson = await res.json();
    }
    return resJson;
  }

  const getRouteSheet = async (deliverDate) => {
    console.log("/getRouteSheet");
    let res = await fetch(process.env.VUE_APP_API_URL + "/getRouteSheet/" + deliverDate, {
      method: "GET",
      headers: {
        Authorization:
          "Basic " +
          btoa(
            localStorage.getItem("username") +
            ":" +
            localStorage.getItem("password")
          ),
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    console.log(res.status);
    let resJson = undefined;
    if (res.status == 200) {
      resJson = await res.json();
    }
    return resJson;
  }

  const addMarkToRouteSheet = async (data) => {
    console.log("/addMarkToRouteSheet");
    let res = await fetch(process.env.VUE_APP_API_URL + "/addMarkToRouteSheet", {
      method: "POST",
      headers: {
        Authorization:
          "Basic " +
          btoa(
            localStorage.getItem("username") +
            ":" +
            localStorage.getItem("password")
          ),
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
    console.log(res.status);
    let resJson = undefined;
    if (res.status == 200) {
      resJson = await res.json();
    }
    return resJson;
  }

  const deleteMark = async (data) => {
    console.log("/deleteMark");

    let res = await fetch(process.env.VUE_APP_API_URL + "/deleteMark", {
      method: "POST",
      headers: {
        Authorization:
          "Basic " +
          btoa(
            localStorage.getItem("username") +
            ":" +
            localStorage.getItem("password")
          ),
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
    console.log(res.status);
    let resJson = undefined;
    if (res.status == 200) {
      resJson = await res.json();
    }
    return resJson;
  }

  const checkMark = async (data) => {
    console.log("/checkMark");

    let res = await fetch(process.env.VUE_APP_API_URL + "/checkMark", {
      method: "POST",
      headers: {
        Authorization:
          "Basic " +
          btoa(
            localStorage.getItem("username") +
            ":" +
            localStorage.getItem("password")
          ),
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
    console.log(res.status);
    let resJson = undefined;
    if (res.status == 200) {
      resJson = await res.json();
    }
    return resJson;
  }

  const scrollToId = async (Id) => {
    await nextTick();
    let el = document.getElementById(Id);
    el.scrollIntoView({ block: "start", behavior: "smooth" });
  };




  return {
    settings,
    getSettings,
    saveSettings,
    dateFormat,
    loadCameraScanner,
    Html5QrcodeObj,
    scan,
    scanner,
    currentOrder,
    currentProductIndex,
    clearScanner,
    scrollToId,
    getRouteSheet,
    deleteMark,
    addMarkToRouteSheet,
    checkMark,
    saveOrder
  };
};
